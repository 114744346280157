import store from '@/store'
import axios from '@axios'
import apiUtil from './apiUtil'

// Athenable-Dependency here
// todo connect to external api
export default {
  resolveRoles(callbackComponet) {
    //bypass spinner since its in conflict with other concurent queries
    //apiUtil.query_pending(true, callbackComponet, loading_container)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    const apiUrl = apiUriPrefix.concat('api?userAdmin=y&getPublicationRoles=y&athenable_tk=', token)
    
    console.log('user.js.resolveRoles calling ttttt:', apiUrl)

    axios
      .get(apiUrl)
      .then(response => {
        // console.log('resonse 1111', response.data)
        callbackComponet.backFromResolveRoles(response.data)
      })
      .catch(error => {
        callbackComponet.backFromResolveRoles(apiUtil.handleError(error))
      })
      .finally(() => {
        //apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  getPublicationUsers(issn, callbackComponet, loadingContainer) {
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    const apiUrl = apiUriPrefix.concat('api?userAdmin=y&fetch_user_list=y&issn=', issn, '&athenable_tk=', token)

    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    axios
      .get(apiUrl)
      .then(response => {
        // console.log('resonse 1111', response.data)
        callbackComponet.backFromGetPublicationUsers(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetPublicationUsers(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  reinviteUsers(requestObj, callbackComponet, loadingContainer) {
    // console.log('tttttttttttttt', requestObj)
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser.token
    const { apiUriPrefix } = store.state.dlStore.apiUriPrefix
    const apiUrl = apiUriPrefix.concat('api?userAdmin=y&athenable_tk=', token)

    axios
      .post(apiUrl, requestObj)
      .then(response => {
        // console.log('resonse 1111', response.data)
        callbackComponet.backFromReinviteUsers(response.data)
      })
      .catch(error => {
        callbackComponet.backFromReinviteUsers(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
}
