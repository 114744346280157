import router from '@/router'
import store from '@/store'

export default {

  handleError(errorObj) {
    let returnError = ''

    if (!errorObj.response) {
      returnError = { error: errorObj }
    } else if (errorObj.response.status === 400 && errorObj.response.data.includes('Unauthorized')) {
      const redirectUrl = window.location.host.concat('/pages/error-404')
      router.push(redirectUrl).catch(() => {})
      returnError = { error: [errorObj.response.status, errorObj.response.data].join(': ') }
    } else if (errorObj.response.status === 405 && errorObj.response.data.includes('Access Denied')) {
      const redirectUrl = window.location.host.concat('/pages/error-404')
      router.push(redirectUrl).catch(() => {})
      returnError = { error: [errorObj.response.status, errorObj.response.data].join(': ') }
    } else if (errorObj.response.status === 400 && errorObj.response.data.includes('NoSuchKey')) {
      const redirectUrl = window.location.host.concat('/pages/error-404')
      router.push(redirectUrl).catch(() => {})
      returnError = { error: [errorObj.response.status, errorObj.response.data].join(': ') }
    } else if (errorObj.response.status === 404) {
      returnError = { error: [errorObj.response.status, errorObj.response.data.message].join(': ') }
    } else if (errorObj.response.status === 503) {
      returnError = { error: [errorObj.response.status, 'Service Unavailable'].join(': ') }
    } else {
      console.log('Unknown Error', errorObj.response)
      returnError = { error: [errorObj.response.status, errorObj.response.data].join(': ') }
    }
 
    return returnError
  },

  query_pending(toggle, callbackComponet, loadingContainer) {
    if (loadingContainer) {
      callbackComponet.query_pending(toggle, loadingContainer)
      return
    }
    // Athenable-Dependency todo - port to b-spinner
    if (toggle) {
      store.commit('pubmillGlobalStore/setPrimaryLoading', 'y')
    } else {
      store.commit('pubmillGlobalStore/setPrimaryLoading', null)
    }
  },
}
