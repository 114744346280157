export default [
  {
    path: '/my-publications',
    name: 'my-publications',
    component: () => import('@/views/pubmill/reader/MyPublications.vue'),
    resource: 'ACL',
    action: 'read',
  },
  {
    path: '/search/:issn/',
    name: 'search1',
    component: () => import('@/views/pubmill/reader/search/SearchMain.vue'),
    resource: 'ACL',
    action: 'read',
  },
  {
    path: '/search/:issn/:tab/',
    name: 'search',
    component: () => import('@/views/pubmill/reader/search/SearchMain.vue'),
    resource: 'ACL',
    action: 'read',
  },
  {
    path: '/reports/:issn',
    name: 'reports',
    component: () => import('@/views/pubmill/admin/Reports.vue'),
    meta: {
      resource: 'ACL',
      action: 'admin',
    },
  },
  {
    // For Manuscript Submission Wizard
    path: '/submit-manuscript/:issn/',
    name: 'submit-manuscript',
    component: () => import('@/views/pubmill/reader/submission/SubmissionWizard.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    // For In Production link to list of issues
    path: '/production-admin/:issn/',
    name: 'production-admin',
    component: () => import('@/views/pubmill/admin/production/ProductionAdmin.vue'),
    meta: {
      resource: 'ACL',
      action: 'admin',
    },
  },
  {
    // For In Production page issue selection 
    // for now same as /publication-admin/:issn/:vol/:issue'
    path: '/production-admin/:issn/:vol/:issue',
    name: 'production-issue-admin',
    component: () => import('@/views/pubmill/admin/issue/PublicationIssueAdmin.vue'),
    meta: {
      resource: 'ACL',
      action: 'admin',
    }, 
  },
  {
    // For In Production page issue selection 
    // for now same as /publication-admin/:issn/:vol/:issue'
    path: '/production-admin/:issn/:vol/:issue/:tab',
    name: 'production-issue-admin-tab',
    component: () => import('@/views/pubmill/admin/issue/PublicationIssueAdmin.vue'),
    meta: {
      resource: 'ACL',
      action: 'admin',
    }, 
  },
  {
    // For Taxonomy
    path: '/taxonomy-admin/:issn/',
    name: 'taxonomy',
    component: () => import('@/views/pubmill/admin/TaxonomyAdmin.vue'),
    meta: {
      resource: 'ACL',
      action: 'admin',
    },
  },
  {
    path: '/issue-intake/:issn/',
    name: 'issue-intake',
    component: () => import('@/views/pubmill/admin/IssueIntake.vue'),
    meta: {
      resource: 'ACL',
      action: 'admin',
    },
  },
  {
    path: '/issue-intake/:issn/',
    name: 'issue-intake',
    component: () => import('@/views/pubmill/admin/IssueIntake.vue'),
    meta: {
      resource: 'ACL',
      action: 'admin',
    },
  },

  /*
  note see STUBB_CREATE_VOL_YEARS for where the following route is dynaically created.
  One route per vol-year is created so the route name has publication-archive-admin-vol(year)
  giving the ability to select the currect item in the left side menu
  */
  { 
    // Issue Archive vol-year links to list of issues
    path: '/publication-archive-admin/:issn/archive/:vol',
    name: 'publication-archive-admin-STUBB_CREATE_VOL_YEARS',
    component: () => import('@/views/pubmill/admin/PublicationArchiveAdmin.vue'),
    meta: {
      resource: 'ACL', 
      action: 'admin',
    },
  },
  {
    // Current Issue Selections
    path: '/current-issue-admin/:issn/:vol/:issue',
    name: 'current-issue-admin',
    component: () => import('@/views/pubmill/admin/issue/PublicationIssueAdmin.vue'),
    meta: {
      resource: 'ACL',
      action: 'admin',
    },
  },
  {
    // Archive issue selections
    path: '/publication-issue-admin/:issn/:vol/:issue',
    name: 'publication-issue-admin',
    component: () => import('@/views/pubmill/admin/issue/PublicationIssueAdmin.vue'),
    meta: {
      resource: 'ACL',
      action: 'admin',
    },
  },
  {
    // Archive issue selections
    path: '/publication-issue-admin/:issn/:vol/:issue/:tab',
    name: 'publication-issue-admin-tab',
    component: () => import('@/views/pubmill/admin/issue/PublicationIssueAdmin.vue'),
    meta: {
      resource: 'ACL',
      action: 'admin',
    },
  },
  
  /************************ 
  * ARTICLE DETAIL ADMIN 
  ************************/
  {
    // For In Production issue link to article admin 
    path: '/production-admin/article-detail/:issn/:vol/:issue/:file_name',
    name: 'article-production-detail-admin',
    component: () => import('@/views/pubmill/admin/detail/ArticleDetailAdmin.vue'),
    meta: {
      resource: 'ACL',
      action: 'admin',
    },
  },
  {
    // For Production DOI link to article admin 
    path: '/production-admin/:issn/doi/:doi_prefix/:doi_suffix',
    name: 'article-admin-production-doi',
    component: () => import('@/views/pubmill/admin/detail/ArticleDetailAdmin.vue'),
    meta: {
      resource: 'ACL',
      action: 'admin',
    },
  },
  {
    // For in Published issue link to article admin 
    path: '/publication-admin/article-detail/:issn/:vol/:issue/:file_name',
    name: 'article-publication-detail-admin',
    component: () => import('@/views/pubmill/admin/detail/ArticleDetailAdmin.vue'),
    meta: {
      resource: 'ACL',
      action: 'admin',
    },
  },
  {
    // For Published DOI link to article admin 
    path: '/publication-admin/:issn/doi/:doi_prefix/:doi_suffix',
    name: 'article-admin-publication-doi',
    component: () => import('@/views/pubmill/admin/detail/ArticleDetailAdmin.vue'),
    meta: {
      resource: 'ACL',
      action: 'admin',
    },
  },

  /************************ 
  * ARTICLE DETAIL WEB READER 
  ************************/
  {
    // For In Production issue link to article web 
    path: '/production-web/article-detail/:issn/:vol/:issue/:file_name',
    name: 'article-production-web-admin',
    component: () => import('@/views/pubmill/reader/web/article/ArticleDetailMain.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    // For Production DOI link to article admin 
    path: '/production-web/:issn/doi/:doi_prefix/:doi_suffix',
    name: 'article-web-production-doi',
    component: () => import('@/views/pubmill/reader/web/article/ArticleDetailMain.vue'),
    meta: {
      resource: 'ACL',
      action: 'admin',
    },
  },
  {
    // For in Published issue link to article detail web 
    path: '/publication-web/article-detail/:issn/:vol/:issue/:file_name',
    name: 'article-publication-detail-admin',
    component: () => import('@/views/pubmill/reader/web/article/ArticleDetailMain.vue'),
    meta: {
      resource: 'ACL',
      action: 'admin',
    },
  },
  {
    // For Published DOI  link to article web 
    path: '/publication-web/:issn/doi/:doi_prefix/:doi_suffix',
    name: 'article-web-publication-doi',
    component: () => import('@/views/pubmill/reader/web/article/ArticleDetailMain.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  }, 

  /************************ 
  * ARTICLE WEB REVIEW SESSIONS
  ************************/
  {
    path: '/article-review/:issn/:vol/:issue/:file_name/:session_id/:reviewer_id',
    name: 'article-review',
    component: () => import('@/views/pubmill/reviewer/ArticleReview.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/article-review-sessions/:issn/:vol/:issue/:file_name',
    name: 'article-review-sessions',
    component: () => import('@/views/pubmill/reviewer/ArticleReviewSessions.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
]
