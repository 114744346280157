export default [
  {
    path: '/pubmill/auth/login',
    name: 'dl-auth-login',
    component: () => import('@/dl_app/idp_acl/DLAuth.vue'),
  },
  {
    path: '/pubmill/auth/rd',
    name: 'dl-auth-rd',
    component: () => import('@/dl_app/idp_acl/DLAuth.vue'),
  },
  {
    path: '/pubmill/auth/rd/wd_token',
    name: 'dl-auth-rd-wdtoken',
    component: () => import('@/dl_app/idp_acl/DLAuth.vue'),
  },
  {
    path: '/pubmill/auth/logout',
    name: 'dl-auth-logout',
    component: () => import('@/dl_app/idp_acl/DLAuth.vue'),
  },
  {
    path: '/pubmill/auth/orcid-login/:function/:pubIssn',
    name: 'dl-auth-orcid-login',
    component: () => import('@/dl_app/idp_acl/DLAuth.vue'),
  },
  {
    path: '/pubmill/auth/orcid-rd/:function/:pubIssn',
    name: 'dl-auth-orcid-rd',
    component: () => import('@/dl_app/idp_acl/DLAuth.vue'),
  },
]
