import Vue from 'vue'
import Vuex from 'vuex'

import dlStore from '@/dl_app/store'
import pubmillGlobalStore from '@/dl_pubmill/store'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    dlStore,
    pubmillGlobalStore,

  },
  strict: process.env.DEV,
})
