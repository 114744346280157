const state = {
  currentPublicationObject: null,
  primaryLoading: null,
  orcidRecord: null,
}

const mutations = { 

  updateCurrentPublicationId(currentState, aVal) {
    if (state.currentPublicationObject && state.currentPublicationObject.id === aVal) {
      return
    }
    console.log('[**store.js.updateCurrentPublicationId**]', aVal)
    state.currentPublicationObject = {
      id: aVal, name: null, shortName: null, issueData: null,
    }
  },
  updateCurrentPublicationName(currentState, aVal) {
    console.log('[**store.js.updateCurrentPublicationName**]', aVal)
    state.currentPublicationObject.name = aVal
  },
  updateCurrentPublicationShortName(currentState, aVal) {
    console.log('[**store.js.updateCurrentPublicationShortName**]', aVal)
    state.currentPublicationObject.shortName = aVal
  },
  updateCurrentPublicationIssueData(currentState, aVal) {
    console.log('[**store.js.updateCurrentPublicationIssueData**]', aVal)
    const record = { volumes: aVal.volumes, currentIssueData: aVal.currentIssueData }
    if (aVal.query_pubdate_online) {
      record.query_pubdate_online = aVal.query_pubdate_online
    }
    state.currentPublicationObject.issueData = record
  },
  setPrimaryLoading(currentState, aVal) {
    state.primaryLoading = aVal
  },
  updateOrcidRecord(currentState, aVal) {
    state.orcidRecord = aVal
  }
}

export default {
  namespaced: true,
  state,
  mutations,
}
