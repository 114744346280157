<template>
  <div />
</template>

<script>

// import { initialAbility } from '@/libs/acl/config'
import userApi from '@/dl_pubmill/apis/user'
import { getLoggedInCookie } from '@/auth/utils'
import { $themeConfig } from '@themeConfig'

export default {
  data() {
    return {
      dlModel: null,
    }
  },
  watch: {
    '$route.params.issn': { 
      handler (newVal, oldVal) {
        if (window.location.href.includes('submission.versionofrecord.com')) {
          return
        }
        console.log('[**App.vue.WATCH**] route.params.issn', this.$route.params.issn, 'newval:', newVal, 'oldVal:', oldVal)
        if (this.$route.params.issn === undefined) {
          this.setRoleDataForNonPublicationPages()
        } else {
          this.$store.commit('pubmillGlobalStore/updateCurrentPublicationId', this.$route.params.issn)
          this.resolveRoles()
          this.getPublicationUsers()
        }
      }     
    },
    '$store.state.pubmillGlobalStore.currentPublicationObject.id': {
      handler (newVal, oldVal) {
        const issn = this.$store.state.pubmillGlobalStore.currentPublicationObject.id
        console.log('[**App.vue.WATCH**] currentPublicationId', issn, 'newval:', newVal, 'oldVal:', oldVal, 'currentPublication')
        this.resolveRoles()
      }
    },
  },
  beforeCreate() {
    //console.log('pppppppppppppp', $themeConfig.app.envVars)
    this.$store.commit('dlStore/setEnv', $themeConfig.app.envVars)
  },
  mounted() {

    //todo remove when manuscrtip function is rempved from this app
    if (window.location.href.includes('submission.versionofrecord.com')) {
      return
    }

    if (!window.location.href.includes('/auth/rd')) {
      this.resolveUser()
    }
  },
  methods: {

    resolveUrlTokenUser() {
      let resolved = true
      if (!this.$store.state.dlStore.AppActiveUser.token) {
        const cookieObj = getLoggedInCookie()
        if (cookieObj) {
          this.$store.dispatch('auth/loginAthenable', cookieObj)
        } else {
          resolved = null
        }
      }
      if (!resolved) {
        this.$router.push({ name: 'error-404-a' })
      }
    },
    resolveLoginTokenUser() {
      //console.log('aaaavvvvv')
      if (!this.$store.state.dlStore.AppActiveUser.token) {
        const cookieObj = getLoggedInCookie()
        if (cookieObj) {
          console.log('[**DLApp.vue.resolveLoginTokenUser**] cookie', this.$store.state.dlStore.cookieName)
          // this.$store.dispatch('auth/loginAthenable', cookieObj)
          this.$store.commit('dlStore/updateUserInfo', cookieObj)
          this.resolveRoles()
        } else {
          console.log('[**DLApp.vue.resolveLoginTokenUser2**] cookie', this.$route.path)
          let currentPath = '/'
          if (window.location.host !== window.location.href) {
            currentPath =  window.location.href.split(window.location.host)[1]
          }
          localStorage.setItem('deepLink', currentPath)
          this.$router.push({ name: 'dl-auth-login' })
        }
      } else {
        let currentPath = '/'
        if (window.location.host !== window.location.href) {
          currentPath =  window.location.href.split(window.location.host)[1]
        }
        localStorage.setItem('deepLink', currentPath)
      }
    },
    resolveModel() {
      // console.log('loc href', window.location.href, this.dlModel)
      if (this.dlModel.type === 'urlToken') {
        this.resolveUrlTokenUser()
      } else {
        this.resolveLoginTokenUser()
      }
    },
    // setRoleDataForNonStubbedObjPages() {
    setRoleDataForNonPublicationPages() {
      const currPath = window.location.href
      const userRole = this.$store.state.dlStore.userRoles
      const readRec = { action: 'read', subject: 'ACL' }
      const adminRec = { action: 'admin', subject: 'ACL' }
      const ability = [readRec]
      if (userRole.sa) {
        ability.push(adminRec)
      }
      if (currPath.includes('/my-publications') && userRole.c) {
        ability.push(adminRec)
      }
      this.$ability.update(ability)
      console.log('set acl ability in setRoleDataForNonPublicationPages', ability)
    },
    // setRoleAndStubbedObjData() {
    setRoleAndPublicationData() {
      const { issn } = this.$route.params
      const { currentPublicationObject } = this.$store.state.pubmillGlobalStore
      // console.log('[**?????DLApp.vue..setRoleAndPublicationData**]', issn, currentPublicationObject)
      if (!issn && !currentPublicationObject) {
        this.setRoleDataForNonPublicationPages()
        return
      }

      this.$store.commit('pubmillGlobalStore/updateCurrentPublicationId', issn)

      const userRole = this.$store.state.dlStore.userRoles
      const readRec = { action: 'read', subject: 'ACL' }
      const adminRec = { action: 'admin', subject: 'ACL' }
      const ability = [readRec]
      if (userRole.sa) {
        ability.push(adminRec)
      } else if (userRole[issn] !== undefined) {
        if (userRole[issn] === '20') {
          ability.push(adminRec)
        }
      }
      this.$ability.update(ability)
      console.log('set acl ability in setRoleAndPublicationData', ability, userRole)
    },
    resolveUser() {
      // Note: When using this base app as a template for real applications, change
      // the following to enter in path filtering and cookie names
      const currPath = window.location.href
      if (currPath.includes('pubmill/auth')) {
        return
      }
      this.dlModel = { type: 'loginToken' }
      this.resolveModel()
    },
    resolveRoles() {
      if (!this.$store.state.dlStore.userRoles) {
        userApi.resolveRoles(this, null)
      } else {
        console.log('[**DLApp.vue..resolveRoles**]', 'dlStore.userRoles intact')
        this.setRoleAndPublicationData()
      }
    },
    backFromResolveRoles(serverData) {
      if (serverData.error) {
        console.log('backFromResolveRoles error:', serverData.error)
      } else {
        //console.log('backFromResolveRoles', serverData)
        this.$store.commit('dlStore/updateUserRoles', serverData)
        this.setRoleAndPublicationData()
      }
    },
    getPublicationUsers() {
      userApi.getPublicationUsers(this.$route.params.issn, this, null)
    },
    backFromGetPublicationUsers(serverData) {
      if (serverData.error) {
        console.log('backFromGetPublicationUsers error:', serverData.error)
      } else {
        this.$store.commit('dlStore/updatePublicationUsers', serverData)
      }
    },
  },
}
</script>
