import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '@/store'

// Routes
// import { canNavigate } from '@/libs/acl/routeProtection'
import dlAuth from './routes/dl-auth'
// import dlStubbedRoutes from './routes/dl-stubbed-routes'
import dlPubmillRoutes from './routes/dl-pubmill-routes'
import dlErrors from './routes/dl-errors'

Vue.use(VueRouter) 

//athenable dynamically create routes from route names for 
//route with  STUBB_CREATE_VOL_YEARS suffix
const dynamicRoutes = []
Object.keys(dlPubmillRoutes).forEach(routeKey => {
  const aRoute = dlPubmillRoutes[routeKey]
  if (aRoute.name.includes('STUBB_CREATE_VOL_YEARS')) {
    const prefix = aRoute.name.split('STUBB_CREATE_VOL_YEARS')[0]
    const total = 200
    let vol = 1
    //let year = 1915 
    while (vol < total) {
      const newRoute = {
        path: aRoute.path,
        name: prefix.concat(vol),
        component: aRoute.component,
        meta: aRoute.meta,
      }
      dynamicRoutes.push(newRoute)
      // console.log('rrrrrrnnnn', newRoute)
      // some years have multiple volumes
      //if (vol !== 49 && vol !== 51 && vol !== 53 && vol !== 55 && vol !== 57 && vol !== 59 && vol !== 60 && vol !== 62  && vol !== 63 && vol !== 65 && vol !== 66) {
      //  year += 1
      //}
      vol += 1
    }
  }
})
Object.keys(dynamicRoutes).forEach(routeKey => {
  const newRoute = dynamicRoutes[routeKey]
  dlPubmillRoutes.push(newRoute)
})
//end athenable dynamically create routes from route names with -STUBB_CREATE: suffixes 

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [

    /* use this for manuscript submissions flows */
    /*
    //{ path: '/', redirect: { name: 'my-publications' } },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      // For Manuscript Submission Wizard
      path: '/submit-manuscript/:issn/',
      name: 'submit-manuscript',
      component: () => import('@/views/pubmill/reader/submission/SubmissionWizard.vue'),
      meta: {
        resource: 'ACL',
        action: 'read',
      },
    },
    ...dlAuth,
    ...dlErrors,
    // dlPubmillRoutes,
     */

    /* use this for production submissions flows */
   
    //for PNAS
    { path: '/', redirect: { path: '/my-publications' } },
    //{ path: '/', redirect: { path: 'search/1091-6490/0' } },
    //for VOR
    //{ path: '/', redirect: path: 'search/1545-7885/0' } },
    {
      path: '*',
      redirect: 'error-404',
    },

    ...dlAuth,
    ...dlErrors,
    ...dlPubmillRoutes,

  ],
})

/* store.state.dlStore.AppActiveUser.token will be populaed
  in DLApp after user is logged in and roles api is called and  resolved
*/
/*
  if (!store.state.dlStore.AppActiveUser.token || to.name.includes('dl-auth-')) {
    return next()
  }

  if (to.name.includes('stubbed-object-detail')) {
    return next()
  }

  console.log('XXXX router.beforeEach', to, canNavigate(to))
  if (!canNavigate(to)) {
    return next({ name: 'error-404-a' })
  }

  return next()
})
*/

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
