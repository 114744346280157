import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

//https://www.npmjs.com/package/vue-sanitize
import VueSanitize from 'vue-sanitize'

import axios from 'axios'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'

Vue.prototype.$http = axios
Vue.use(VueSanitize)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core style
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

//Athenable Dependency
// leave undefined to use the browser's locale,
// or use a string like 'en-US' to override it.

// eslint-disable-next-line
Vue.filter('formatNumber', function (n) {  
  const value = n.toLocaleString(
    undefined,
    { minimumFractionDigits: 0 }
  )
  return value
})
// eslint-disable-next-line
Vue.filter('formatISODate', function (isoString) { 
  let tmp =  isoString
  if (!(isoString.endsWith('Z') || isoString.endsWith('z'))) {
    tmp = isoString.concat('Z')
  } 
  const aDate = new Date(tmp)
  
  return aDate.toLocaleDateString().concat(' ', aDate.toLocaleTimeString())
  
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
