/* const userInfoLocalStorage = JSON.parse(localStorage.getItem('userInfo')) || {}

const getUserInfo = () => {
  const userInfo = {}
  // Include properties from localStorage
  Object.keys(userInfoLocalStorage).forEach(key => {
    if (userInfo[key] === undefined && userInfoLocalStorage[key] !== null) userInfo[key] = userInfoLocalStorage[key]
  })
  return userInfo
}
*/

const state = {
  app: null,
  env: null,
  cookieName: null,
  apiUriPrefix: null,
  mediaImagePrefix: null,
  mediaAudioPrefix: null,
  userRoles: null,
  publicationUsers: null,
  AppActiveUser: {},
}

const mutations = {

  setEnv(currentState, aVal) {
    state.env = aVal.ENV
    state.app = aVal.APP
    state.cookieName = aVal.COOKIE_NAME

    state.mediaImagePrefix = 'https://'.concat(aVal.ENV, aVal.MEDIA_IMAGE_SUFFIX)
    let envString = state.env
    if (state.env === 'prod') {
      envString = ''
    }
    state.apiUriPrefix = 'https://api.'.concat(envString, state.app, '/', state.env, '/')

    state.mediaAudioPrefix = state.mediaImagePrefix
  },
  updateUserRoles(currentState, aVal) {
    state.userRoles = aVal
  },
  updateUserInfo(currentState, payload) {
    Object.keys(payload).forEach(property => {
    // for (const property of Object.keys(payload)) {
      console.log('setting AppActiveUser property', property)
      if (payload[property] !== null) {
        state.AppActiveUser[property] = payload[property]
      }
    })
  },
  updatePublicationUsers(currentState, aVal) {
    state.publicationUsers = aVal
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
